import { createBrowserRouter } from 'react-router-dom';
import Layout from '../Components/Layout';
import { About, Blog, Contact, Home } from '../Pages';

export const router = createBrowserRouter([
    {
        element: <Layout />,
        children: [
            {
                path: '/',
                element: <Home />,
            },
            {
                path: 'contact',
                element: <Contact />,
            },
            {
                path: 'blog',
                element: <Blog />,
            },
            {
                path: 'about',
                element: <About />,
            },
        ],
    },
]);
