import React, { useEffect } from 'react';

const Blog = () => {
    useEffect(() => {
        document.title = 'Blogs | Daffa Hanifisyafiq';
    }, []);

    return (
        <>
            <h1 className="font-montserrat block font-bold text-base dark:text-slate-100">Blog</h1>
            <span className="dark:text-slate-100">Coming soon...</span>
        </>
    );
};

export default Blog;
