import React from 'react';
import { motion } from 'framer-motion';

import Container from './Container';
import Header from './Header';

const Layout = ({ children }) => {
    return (
        <>
            <motion.div className="w-full min-h-screen dark:bg-slate-800" initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
                <Container>
                    <Header />
                    <div className="content">{children}</div>
                </Container>
            </motion.div>
        </>
    );
};

export default Layout;
