import React from 'react';
import { motion } from 'framer-motion';

const Button = ({ children, from, via, to }) => {
    return (
        <>
            <motion.button whileHover={{ scale: 1.2 }} whileTap={{ scale: 0.8 }} className={`h-auto w-auto rounded-md bg-gradient-to-r ${from} ${via} ${to} p-1 transition ease-in-out delay-75   duration-300`}>
                <div className="flex h-full w-full items-center justify-center bg-slate-800 px-3 py-3 font-montserrat text-white">{children}</div>
            </motion.button>
            {/* <button className={`h-auto w-auto rounded-md bg-gradient-to-r ${from} ${via} ${to} p-1 transition ease-in-out delay-75 hover:-translate-y-1 hover:scale-110  duration-300`}>
                <div className="flex h-full w-full items-center justify-center bg-slate-800 px-3 py-3 font-montserrat text-white">{children}</div>
            </button> */}
        </>
    );
};

export default Button;
