import { useEffect } from 'react';
import { Link } from 'react-router-dom';

import { ReactComponent as JsLogo } from '../Assets/javascript.svg';
import { ReactComponent as TsLogo } from '../Assets/typescript.svg';
import { ReactComponent as ReactLogo } from '../Assets/react.svg';
import { ReactComponent as TailwindLogo } from '../Assets/tailwindcss.svg';
import Button from '../Components/Button';

const Home = () => {
    useEffect(() => {
        document.title = 'Home | Daffa Hanifisyafiq';
    }, []);

    return (
        <>
            <div className="">
                <div className="">
                    <h1 className="font-montserrat text-5xl md:text-6xl font-extrabold mt-24 text-slate-800 dark:text-white">Daffa Hanifisyafiq</h1>
                    <div className="flex items-center my-5 gap-2">
                        <p className="font-montserrat text-slate-800 font-medium dark:text-slate-100">Frontend Developer</p>
                        <div className="flex gap-2">
                            <TsLogo style={{ fill: '#3178C6' }} className="w-5" />
                            <JsLogo style={{ fill: '#F7DF1E' }} className="w-5" />
                            <ReactLogo style={{ fill: '#61DAFB' }} className="w-5" />
                            <TailwindLogo style={{ fill: '#06B6D4' }} className="w-5" />
                        </div>
                    </div>
                    <div className="">
                        <p className="font-montserrat text-slate-800 dark:text-slate-100">Currently working with javascript environment especially in frontend development, and i still trying to be better like elon musk.</p>
                    </div>
                    <div className="my-5 flex gap-5">
                        <Link to={'blog'}>
                            <Button from="from-pink-500" via="via-purple-500" to="to-yellow-500">
                                Read the blog
                            </Button>
                        </Link>
                        <Link to={'about'}>
                            <Button from="from-yellow-500" via="via-purple-500" to="to-pink-500">
                                Learn about me
                            </Button>
                        </Link>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Home;
