import React, { useEffect, useState } from 'react';
import { Tooltip } from 'react-tooltip';

import { ReactComponent as JsLogo } from '../Assets/javascript.svg';
import { ReactComponent as ReactLogo } from '../Assets/react.svg';
import { ReactComponent as TsLogo } from '../Assets/typescript.svg';
import { ReactComponent as LinkedinLogo } from '../Assets/linkedin.svg';
import { ReactComponent as GithubLogo } from '../Assets/github.svg';
import { ReactComponent as TwitterLogo } from '../Assets/twitter.svg';
import { ReactComponent as InstagramLogo } from '../Assets/instagram.svg';
import { ReactComponent as TailwindCssLogo } from '../Assets/tailwindcss.svg';

import SiGanteng from '../Assets/me.jpg';

import 'react-tooltip/dist/react-tooltip.css';

const About = () => {
    const [greeting, setGreeting] = useState('');

    useEffect(() => {
        const currentHours = new Date().getHours();
        if (currentHours < 12) {
            setGreeting('good morning');
        } else if (currentHours < 18) {
            setGreeting('good afternoon');
        } else {
            setGreeting('good night');
        }
    }, []);

    useEffect(() => {
        document.title = 'About | Daffa Hanifisyafiq';
    }, []);

    return (
        <>
            <div className="flex flex-col gap-3">
                <div className="flex flex-col gap-2">
                    <div>
                        <span className="font-montserrat block font-bold text-base dark:text-slate-100">About</span>
                        <span className="text-2xl font-montserrat font-bold text-transparent bg-clip-text bg-gradient-to-r from-pink-500 to-indigo-500">Daffa Hanifisyafiq</span>
                    </div>
                    <div className="flex flex-row gap-3">
                        <a href="https://www.linkedin.com/in/daffahanifisyafiq/" target="_blank" rel="noreferrer">
                            <LinkedinLogo className="social-link hover:fill-[#0A66C2]" data-tooltip-id="my-tooltip" data-tooltip-content="LinkedIn" data-tooltip-place="bottom" />
                        </a>
                        <a href="https://github.com/mazc0de" target="_blank" rel="noreferrer">
                            <GithubLogo className="social-link hover:fill-[#181717]" data-tooltip-id="my-tooltip" data-tooltip-content="Github" data-tooltip-place="bottom" />
                        </a>
                        <a href="https://twitter.com/sundaysuckss" target="_blank" rel="noreferrer">
                            <TwitterLogo className="social-link hover:fill-[#1DA1F2]" data-tooltip-id="my-tooltip" data-tooltip-content="Twitter" data-tooltip-place="bottom" />
                        </a>
                        <a href="https://www.instagram.com/daffahnfy/" target="_blank" rel="noreferrer">
                            <InstagramLogo className="social-link hover:fill-[#E4405F]" data-tooltip-id="my-tooltip" data-tooltip-content="Instagram" data-tooltip-place="bottom" />
                        </a>
                    </div>
                </div>
                <div className="mt-5">
                    <div className="float-right ml-3 mb-1 w-40 md:w-72 overflow-hidden">
                        <img src={SiGanteng} alt="siganteng" className="rounded-lg" data-tooltip-id="my-tooltip" data-tooltip-content="(>﹏<)" data-tooltip-place="bottom" />
                    </div>
                    <article className="prose text-lg leading-8 text-gray-600 dark:prose-invert dark:text-gray-300 font-montserrat">
                        <p>
                            Hello there! {greeting}, I'm <span className="font-medium">Daffa Hanifisyafiq</span>, I am a software engineer at{' '}
                            <a href="https://mitrakasihperkasa.com/" target="_blank" rel="noreferrer" className="border-b-2 border-indigo-800 no-underline text-indigo-500">
                                Mitra Kasih Perkasa.
                            </a>{' '}
                        </p>
                        <p>
                            I really like things about <span className="font-semibold">technology</span>, especially programming, learning is my way to knowing there are many things I don't know yet. I also have a{' '}
                            <span className="font-semibold">hobby</span> of playing games and collecting 1:64 scale diecast.
                        </p>
                        <p>
                            I am currently working at a <span className="font-semibold">traffic intelligence company</span> as a frontend web developer in the IT department.
                        </p>
                        <p>
                            I opened a side job which is freelance for making a website using React js with the cheapest price but with the best service, if you are <span className="font-semibold">interested</span> hit me on{' '}
                            <a href="https://www.linkedin.com/in/daffahanifisyafiq/" target="_blank" rel="noreferrer" className="border-b-2 border-[#0A66C2] no-underline text-[#0A66C2]">
                                Linkedin
                            </a>
                        </p>
                    </article>
                </div>
                <div>
                    <span className="text-2xl font-montserrat font-bold text-transparent bg-clip-text bg-gradient-to-r from-pink-500 to-indigo-500">Current tech stack</span>
                    <div className="flex flex-row gap-3 mt-3">
                        <TsLogo className="tech-stack-list hover:fill-[#3178C6]" data-tooltip-id="my-tooltip" data-tooltip-content="Javascript" data-tooltip-place="bottom" />
                        <JsLogo className="tech-stack-list hover:fill-[#F7DF1E]" data-tooltip-id="my-tooltip" data-tooltip-content="Javascript" data-tooltip-place="bottom" />
                        <ReactLogo className="tech-stack-list hover:fill-[#61DAFB]" data-tooltip-id="my-tooltip" data-tooltip-content="React JS" data-tooltip-place="bottom" />
                        <TailwindCssLogo className="tech-stack-list hover:fill-[#06B6D4]" data-tooltip-id="my-tooltip" data-tooltip-content="Tailwind CSS" data-tooltip-place="bottom" />
                        <Tooltip id="my-tooltip" />
                    </div>
                </div>
            </div>
        </>
    );
};

export default About;
